export class Labels {
  readonly xls = {
    st: 1,
    nu: 0,
  };

  //GENERICO----------------------------------------------------------------------------------------------------------------
  readonly icon = {
    confirmDialogQuestion: 'fa fa-question-circle',
    divToolbar: 'fa fa-bars',
    headerPanelList: 'fa fa-list-alt',
    headerPanelEdit: 'fa fa-edit',
    menu: 'far fa-folder', //'fa fa-folder-o',
    prioridadeEmergencial: 'fa fa-exclamation-triangle',
    rowExpansionClosed: 'pi pi-chevron-right',
    rowExpansionOpened: 'pi pi-chevron-down',
    situacaoAberto: 'far fa-circle',
    situacaoEmAdamento: 'fa fa-adjust',
    situacaoFechado: 'fa fa-circle',
    subMenu: 'far fa-file-alt', //'fa fa-file-text-o',
    subMenuLink: 'fas fa-external-link-alt', //'fa fa-external-link'
  };
  //button
  readonly b = {
    adicionar: 'Add',
    adicionarIcon: 'fa fa-plus',
    atualizarIcon: 'fa fa-refresh',
    downloadIcon: 'fas fa-download',
    downloadTitle: 'Clique para fazer o download do arquivo',
    consultar: 'consultar',
    consultarIcon: 'fa fa-search',
    confirmar: 'confirmar',
    confirmarIcon: 'fas fa-check',
    copiar: 'copiar',
    copiarIcon: 'far fa-copy',
    copiarTitle: 'Clique para gerar uma cópia a partir deste registro',
    cancelar: 'cancelar',
    cancelarIcon: 'fa fa-times',
    editarIcon: 'fas fa-pencil-alt',
    editarTitle: 'Editar Registro',
    excluir: 'excluir',
    excluirIcon: 'fas fa-trash-alt',
    excluirTitle: 'Excluir Registro',
    exportarCsv: 'exportar csv',
    exportarCsvIcon: 'far fa-file-excel',
    exportarExcel: 'exportar excel',
    exportarExcelIcon: 'far fa-file-excel',
    exportarPdf: 'exportar pdf',
    exportarPdfIcon: 'far fa-file-pdf',
    enviar: 'enviar',
    importar: 'importar',
    importarIcon: 'far fa-file-excel',
    limpar: 'limpar',
    limparIcon: 'fa fa-eraser',
    limparTitle: 'Limpar Formulário',
    novo: 'novo',
    novoIcon: 'far fa-file',
    novoAdicionarIcon: 'fa fa-plus',
    novoAdicionarTitle: 'Cadastrar Novo Registro',
    avancar: 'avançar',
    avancarIcon: 'fa fa-arrow-right',
    salvar: 'salvar',
    salvarIcon: 'far fa-save',
    selecionarTodos: 'selecionar todos',
    selecionarArquivo: 'selecionar arquivo',
    limparTodos: 'limpar todos',
    uploadIcon: 'fas fa-upload',
    voltar: 'voltar',
    voltarIcon: 'fas fa-arrow-left',
    adicionarDireitaIcon: 'fas fa-angle-right',
    adicionarDireitaTitle: 'clique para adicionar o(s) item(s) para listagem da direita.',
    adicionarTodosDireitaIcon: 'fas fa-angle-double-right',
    adicionarTodosDireitaTitle: 'clique para adicionar todos itens para listagem da direita.',
    removerEsquerdaIcon: 'fas fa-angle-left',
    removerEsquerdaTitle: 'clique para retornar o(s) item(s) para listagem da esquerda.',
    removerTodosEsquerdaIcon: 'fas fa-angle-double-left',
    removerTodosEsquerdaTitle: 'clique para retornar todos itens para listagem da esquerda.',
  };
  //calendar-locale
  readonly calendarBR = {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'],
    monthNamesShort: ['jan', 'feb', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar',
  };

  readonly placeholder = {
    quantidade: 'Quantidade',
  };

  readonly msg_success = 'SUCESSO: ';
  readonly msg_warn = 'ALERTA:';
  readonly msg_info = 'INFO:';
  readonly msg_error = 'FALHA:';

  //mask
  readonly mask = {
    cpf: '999.999.999-99',
    cep: '99999-999',
    data: '99/99/9999',
  };
  //date
  readonly calendar = {
    default: 'dd/mm/yy',
    dataHora: 'dd/mm/yy HH:mm',
    dataHoraS: 'dd/mm/yy HH:mm:ss',
    hora: 'HH:mm',
    horaS: 'HH:mm:ss',
    mesAno: 'mm/yy',
  };
  readonly pipe = {
    dataDefault: 'dd/MM/yyyy',
    dataHora: 'dd/MM/yyyy HH:mm',
    dataHoraS: 'dd/MM/yyyy HH:mm:ss',
    hora: 'HH:mm',
    horaS: 'HH:mm:ss',
    mesAno: 'MM/yyyy',
    timezone: 'UTC-3',
  };
  //generico
  readonly g = {
    abertura: 'Abertura',
    acao: 'Ação',
    adicionar: 'Adicionar',
    arquivo: 'Arquivo',
    ativo: 'Ativo',
    ate: 'até',
    baterFoto: 'Bater Foto',
    cadastrar: 'Cadastrar',
    cpf: 'CPF',
    codigo: 'Código',
    cod: 'Cód.',
    confirmacao: 'Confirmação',
    categoria: 'Categoria',
    cancelar: 'Cancelar',
    dataNascimento: 'Data Nascimento',
    dataCriacao: 'Data Criacao',
    dataAbertura: 'Data Abertura',
    dataFechamento: 'Data Fechamento',
    entrega: 'Entrega',
    emails: 'Emails',
    editar: 'Editar',
    excluir: 'Excluir',
    fechamento: 'Fechamento',
    fechar: 'Fechar',
    filtros: 'Filtros de Consulta',
    id: 'ID',
    item: 'Item',
    itens: 'Itens',
    inventario: 'Inventário',
    lista: 'Lista',
    limpar: 'Limpar',
    nome: 'Nome',
    nao: 'Não',
    observacao: 'Observação',
    opcoes: 'Opções',
    porcentagem: 'Porcentagem',
    sim: 'Sim',
    un: 'Un.',
    usuarioCriacao: 'Usuário Criação',
    usuarioAbertura: 'Usuário Abertura',
    usuarioFechamento: 'Usuário Fechamento',
    situacao: 'Situação',
    selecionarArquivo: 'selecionar arquivo',
    //selecionarNovoArquivo: 'selecionar novo arquivo',
    tipo: 'Tipo',
    total: 'Total',
  };
  //graficos
  readonly grafico = {
    cores: ['#FF6384','#36A2EB','#FFCE56','#ff0000','#ffb200','#0037ff','#18b700','#c700ff','#ff8300','#008B8B','#A0522D','#00008B','#FAEBD7'],
  };
  //table
  readonly table = {
    rowsPerPageOptions: [25, 50, 100], //combo com quantidade de linhas que podem ser mostradas
    rows: 25, //quantidade default de linhas por página
    pageLinks: 10, //quantidade de links de páginas visíveis
  };
  //mensagens
  readonly m = {
    apenasNumero: '(apenas números)',
    confirmarExcluir: 'Você tem certeza que deseja excluir este registro? Essa ação não poderá ser desfeita.',
    emptyMessage: 'nenhum registro encontrado.',
    required: 'campo obrigatório.',
    selecioneOne: 'selecione uma opção',
    selecioneMulti: 'selecione uma ou mais opções',
    todasOpcoes: 'todas opções',
    itensSelecionados: 'itens selecionados',
    autoComplete: 'preenche as inciais ou parte do nome e aguarde a listagem dos registros encontrados.',
    falhaSelecionarArquivo: 'Ocorreu uma falha ao adicionar o arquivo selecionado: ',
    formVazio: 'Preencha o formulário!',
    formInvalido: 'Existem campos obrigatórios que ainda não foram preenchidos.',
    anexoObrigatorio: 'Tire uma foto do item de inventário!',
    semInventarios: 'Nenhum inventário cadastrado! Adicione um novo clicando no botão "+" localizado no canto inferior direito.',
    useDispositivoMovelComCamera: 'Para usar essa função é obrigatório o uso de um dispositivo movel com câmera!',
    valorInvalido: 'valor inválido.',    
    beneficiosVale: 'Selecione umas das opções de vale',
  };
  //simbolos
  readonly s = {
    monetario: 'R$',
  };

  //ACESSO----------------------------------------------------------------------------------------------------------------
  //usuario
  readonly usuario = {
    loginFalhaComunicacao: 'Falha na comunicação, serviço indisponível.',
    loginUnknownErro: 'Não foi possível concluir está ação, serviço indisponível.',
  };

  //BEACON----------------------------------------------------------------------------------------------------------------
  readonly device = {
    arquivoCsv: 'lista_de_devices',
    edit: 'Cadastro de Beacon',
    label: 'Beacon',
    list: 'Lista de Beacons',
    updatedAt: 'Data Atualização',
    mac: 'Mac',
  };
  readonly gateway = {
    alertas: 'Alertas',
    alertasDescricao: 'Informe os endereços de emails dos responsáveis que receberão notificações quando o limite (mínmo/máximo) de beacons for atingido neste gateway.',
    emailSupervisorTitle: 'Para adicionar novos emails, preencha o email e pressione ENTER. Para cada email novo esse processo deve ser repetido. Após clique em SALVAR.',
    arquivoCsv: 'lista_de_gateways.',
    deviceMinimo: 'Mínimo',
    deviceMaximo: 'Máximo',
    edit: 'Cadastro de Gateway',
    label: 'Gateway',
    list: 'Lista de Gateways',
    updatedAt: 'Data Atualização',
    patrimonio: 'Patrimônio',
    mac: 'Mac',
  };
  readonly localizacao = {
    label: 'Localização',
  };

  //RH----------------------------------------------------------------------------------------------------------------
  readonly lancamento = {
    arquivoCsv: 'lista_de_devices',
    edit: 'Lançamento de Horas',
    label: 'Lançamento',
    list: 'Lançamentos',
    updatedAt: 'Data Atualização',
    entrada: 'Entrada',
    saida: 'Saída',
    descricao: 'Nº BA / Descrição',
    salvar: 'Foto Rosto e Salvar',
    feriado: 'Feriado Municipal, Feriado Estadual ou Embarcados Folga.',
    nomeTipoLancamento: 'Tipo',
  };

  readonly alterarEmail = {
    edit: 'Alterar Email',
    label: 'Email de notificação',
  };

  readonly candidatoVaga = {
    m: {
      existemDadosReprovados: 'Importante: <u>alguns dos dados preenchidos em seu cadastro foram REPROVADOS</u> pelo recrutador. Verifique os itens abaixo que necessitam de correção, clicando no botão: <br/> <b><i class="fas fa-exclamation-triangle"></i>corrigir</b>.'
    }
  }

  readonly documentoCandidato = {
    motivoReprovacao: '<b>Motivo da Reprovação do Documento:</b> '
  }
  
  readonly exameCandidato = {
    motivoReprovacao: '<b>Motivo da Reprovação do Exame:</b> '
  }

  readonly dadosCandidato = {
    motivoReprovacao: '<b>Motivo da Reprovação dos Dados Cadastrais:</b> ',
    cidadeNascimentoTitle: '(preencha as iniciais da cidade e selecione uma opção)',
  }



  //ESTOQUE-------------------------------------------------------------------------------------------------------------
  readonly inventario = {
    list: 'Inventários',
    title: 'Inventário',
    item: 'Item de Inventário',
    itens: 'Itens de Inventário',
    material: 'Material',
    estadoConservacao: 'Estado de Conservação',
    codigoPatrimonio: 'Código de Patrimônio',
    observacaoFuncionario: 'Observação do Funcionário',
    anexo: 'Anexo',
    fotoItem: 'Foto do Item',
  };

  //EXAMES-------------------------------------------------------------------------------------------------------------
  readonly exame = {
    label: 'Exames',
    descricao: 'veja os exames que já estão agendados para você e preencha os que você já fez.',
    tipoExame: 'Tipo Exame',
    dataAgendamento: 'Data Agendamento',
    medico: 'Médico',
    clinica: 'Clínica',
    local: 'Endereço da Clínica',
    foto: 'Adicionar Foto do Exame',
  };

  readonly rhAlterarSenha = {
    edit: 'Alterar Senha',
    novaSenha: 'Nova Senha',
    senhaAtual: 'Senha Atual',
  };
  
}
