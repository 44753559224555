import { AuthService } from 'src/app/auth/auth.service';
import { map, catchError } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';

@Injectable()
export class PreCadastroResolverService implements Resolve<any> {
  constructor(private candidatoVagaHttpService: CandidatoVagaHttpService, private authService: AuthService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.candidatoVagaHttpService.loadCandidatoAtivo().pipe(
      map((res: any) => {
        sessionStorage.setItem('idVaga', res.idVaga);
        return res;
      }),
      catchError((error) => {
        alert(error.retorno.mensagem);
        this.authService.logout();
        //return throwError(error);
        return of(null);
      })
    );
  }
}
