import { AuthGuard } from './guard/auth.guard';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AuthService } from './auth.service';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    //BrowserModule, -- ser importado apenas uma vez no appModule
    FormsModule,
    /*PRIMENG*/
    ButtonModule,
    CheckboxModule,
    PasswordModule,
  ],
  providers: [
    AuthService,
    /*GUARDS*/
    AuthGuard,
  ],
  declarations: [
    //LoginComponent
  ],
  exports: [
    //LoginComponent
  ],
})
export class AuthModule {}
