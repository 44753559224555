import { MessageGrowlService } from '../../_util/message-growl/message-growl.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Labels } from '../../core/constant/labels';
import { AuthService } from '../auth.service';
import { Component } from '@angular/core';
import { Mensagens } from 'src/app/core/constant/messages';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
})
export class RecuperarSenhaComponent {
  email: string;
  disabled: boolean = false; //VAI DESATIVAR O FORMULÁRIO DURANTE O LOGIN, PARA O USUÁRIO NÃO FICAR CLICANDO

  constructor(
    private authService: AuthService,
    private messageGrowlService: MessageGrowlService,
    private msg: Mensagens,
    private router: Router,
    public lbs: Labels
  ) {}

  ngOnInit() {}

  onRecuperarSenha() {
    if (this.email) {
      this.disabled = true;
      this.authService.recuperarSenha(this.email).subscribe(
        (retorno) => {
          this.messageGrowlService.showSuccess(this.msg.msg_warn, retorno.mensagem);
          this.router.navigate(['/login']);
        },
        (error: HttpErrorResponse) => {
          const message = error.error.mensagem || 'Não foi possível redefinir senha.';
          this.disabled = false;
          this.messageGrowlService.showWarn(this.msg.msg_warn, message);
        },
        () => {
          this.disabled = false;
        }
      );
    } else {
      this.messageGrowlService.showWarn(this.msg.msg_warn, this.lbs.m.formVazio);
    }
  }
}
