import { ExameCandidatoEdit } from './../../../../m_rh/pages/pre-cadastro/model/exame-candidato-edit';
import { map } from 'rxjs/operators';
import { DocumentoCandidatoEdit } from 'src/app/m_rh/pages/pre-cadastro/model/documento-candidato-edit';
import { CandidatoVagaEdit } from 'src/app/m_rh/pages/pre-cadastro/model/candidato-vaga-edit';
import { AbstractGenericHttpService } from 'src/app/core/service/abstract-generic-http.service';
import { ParamUtilService } from 'src/app/_util/param-util/param-util.service';
import { HttpClient } from '@angular/common/http';
import { Path } from 'src/app/core/constant/path';
import { Injectable } from '@angular/core';

const PATH = Path.RH + 'candidato-vaga/';
const URL_DADOSCADASTRAIS = PATH + 'dados-cadastrais';
const URL_DOCUMENTOSVAGA = PATH + '{id}/documentos-vaga';
const URL_EXAMESVAGA = PATH + '{id}/exames-vaga';
const URL_CANDIDATOATIVO = PATH + 'candidato-ativo';
const URL_EXISTECANDIDATOATIVO = PATH + 'existe-candidato-ativo';
const URL_PROGRESSOCANDIDATO = PATH + 'progresso-candidato';
const URL_CPF = PATH + 'cpf';

@Injectable({
  providedIn: 'root',
})
export class CandidatoVagaHttpService extends AbstractGenericHttpService {
  constructor(protected HttpClient: HttpClient, private paramUtil: ParamUtilService) {
    super(HttpClient);
  }

  salvarDadosCadastrais(bean: CandidatoVagaEdit): any {
    return this._doPut(URL_DADOSCADASTRAIS, bean);
  }

  salvarDocumentoCandidato(bean: DocumentoCandidatoEdit, idVaga: number): any {
    const url = this.paramUtil.jsonToUrl(URL_DOCUMENTOSVAGA, { id: idVaga });
    return this._doPost(url, bean);
  }

  salvarExameCandidato(bean: ExameCandidatoEdit, idVaga: number): any {
    const url = this.paramUtil.jsonToUrl(URL_EXAMESVAGA, { id: idVaga });
    return this._doPost(url, bean);
  }

  //LIST

  listarDocumentosVaga(idVaga: number): any {
    const url = this.paramUtil.jsonToUrl(URL_DOCUMENTOSVAGA, { id: idVaga });
    return this._doGetRetorno(url);
  }

  listarExamesVaga(idVaga: number): any {
    const url = this.paramUtil.jsonToUrl(URL_EXAMESVAGA, { id: idVaga });
    return this._doGetRetorno(url);
  }

  //LOAD

  /**CONSULTA O CANDIDATOSESSAO ATIVO ASSOCIADO AO USUÁRIO*/
  loadCandidatoAtivo(): any {
    return this._doGetRetorno(URL_CANDIDATOATIVO);
  }

  /**CONSULTA OS DADOSCADASTRAIS DO CANDITADO. UTILIZADO QUANDO EXISTE A NECESSIDADE DE CORRIGIR OS DADOS DO CANDIDATO.*/
  loadDadosCadastrais(): any {
    return this._doGetRetorno(URL_DADOSCADASTRAIS);
  }

  /**CONSULTA O ANDAMENTO DO PREENCHIMENTO DO MENU. [0-DADOSCADASTRAISEMANDAMENTO, 1-DOCUMENTOSEMANDAMENTO] */
  loadProgressoCandidato(): any {
    return this._doGetRetorno(URL_PROGRESSOCANDIDATO);
  }

  /**CONSULTA O CPF DO CANDIDATO SESSAO*/
  loadCpfSessao(): any {
    return this._doGetRetorno(URL_CPF);
  }

  /**CONSULTA SE EXISTE UM CANDIDATOVAGA ATIVO ASSOCIADO AO USUÁRIO*/
  existeCandidatoAtivo(): any {
    return this._doGetRetorno(URL_EXISTECANDIDATOATIVO);
  }
}
