import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Labels } from 'src/app/core/constant/labels';
import { ProgressoCandidatoList } from 'src/app/m_rh/pages/pre-cadastro/model/progresso-candidato-list';
import { LoadingService } from 'src/app/_util/loading/loading.service';

@Component({
  selector: 'app-menu-pre-cadastro',
  templateUrl: './menu-pre-cadastro.component.html',
  styleUrls: ['./menu-pre-cadastro.component.scss'],
})
export class MenuPreCadastroComponent implements OnInit {
  mostrarMensagemReprovacao: boolean = false;
  subscription: Subscription = new Subscription();
  progresso: ProgressoCandidatoList;
  // progressoDadosCadastrais: { pendente: boolean; reprovado: boolean; motivo: string };
  // progressoDocumentos: { pendente: boolean; reprovado: boolean };

  constructor(
    public lbs: Labels,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    //private formBuilder: FormBuilder,
    //private inputUtil: InputUtilService,
    //public msgUtil: MessageUtilService,
    private loadingService: LoadingService //private candidatoVagaHttpService: CandidatoVagaHttpService
  ) {}

  ngOnInit() {
    this.carregarPagina();
  }

  carregarPagina(): void {
    //this.loadingService.on();
    /*LÓGICA DE CARREGAMENTO DA PÁGINA*/
    this.subscription.add(
      this.activatedRoute.data.subscribe(
        (data: { dataResolver: any }) => {
          // console.log(data);
          // console.log(data.dataResolver);
          // this.progressoDadosCadastrais = {
          //   pendente: data.dataResolver[0][0],
          //   reprovado: data.dataResolver[0][1],
          //   motivo: data.dataResolver[0][2],
          // };
          // this.progressoDocumentos = {
          //   pendente: data.dataResolver[1][0],
          //   reprovado: data.dataResolver[1][1],
          // };

          // console.log(this.progressoDadosCadastrais);
          // console.log(this.progressoDocumentos);
          this.progresso = data.dataResolver;
          this.mostrarMensagemReprovacao =
            this.progresso.dadosCadastraisReprovados ||
            this.progresso.documentosReprovados ||
            this.progresso.examesReprovados;

          this.loadingService.off();
        },
        (error) => {
          this.loadingService.off();
        }
      )
    );
  }

  //

  irParaDadosCadastrais(corrigir: boolean): void {
    //this.loadingService.on();
    this.router.navigate(['../dados-cadastrais', corrigir], { relativeTo: this.activatedRoute });
  }

  irParaDocumentos(): void {
    //this.loadingService.on();
    this.router.navigate(['../documentos'], { relativeTo: this.activatedRoute });
  }

  irParaExames(): void {
    //this.loadingService.on();
    this.router.navigate(['../exames'], { relativeTo: this.activatedRoute });
  }
}
